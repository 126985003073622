import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '@/router'
import { asyncRoutes } from '@/router'
import { getToken } from '@/utils/auth'
import { mainStore } from './store'
import { storeStep, storeApplyEdit, storeUserInfo, storeNoticeMark } from '@/api/home'

const whiteList = ['Login', 'SignIndex']

router.beforeEach((to, from, next) => {
  const hasToken = getToken()
  const store = mainStore()
  NProgress.start()
  if (!store.totalStep) {
    storeJoin(to, next)
  } else {
    if (hasToken && store.auth.length) {
      storeNoticeMark().then(res => {
        res.data.unread ? store.isBell = true : store.isBell = false
      })
      if (to.name === 'Login') {
        if (store.totalStep === 1) {
          router.replace('/sign/index')
        } else if (store.totalStep === 2) {
          router.replace('/index')
        }
      } else {
        if (store.totalStep === 1) {
          if (to.name === 'SignIndex') {
            next()
          } else {
            router.replace('/sign/index')
          } 
        } else if (store.totalStep === 2) {
          next()
        }
      } 
    } else {
      if (hasToken) storeJoin(to, next)
      if (whiteList.indexOf(to.name) !== -1) {
        next()
      } else {
        next({ path: '/login' })
      }
    }
  }
})
// 入驻申请逻辑
function storeJoin(to, next) {
  const store = mainStore()
  const hasToken = getToken()
  if (hasToken) {
    storeStep().then(res => {
      store.totalStep = res.data.step
      store.step = 4
      store.storeInfo = res.data.store
      store.menus = res.data.menus
      store.auth = res.data.unique_auth // 用户权限数组
      authFilter() // 路由权限筛选
      storeUserInfo().then(res => { // 获取用户信息
        store.userInfo = res.data
      })
      if (res.data.store && res.data.store.audit_status === 3) {
        storeApplyEdit().then(res => {
          res.data.area = [res.data.main_province, res.data.main_city]
          res.data.region = [res.data.store_province, res.data.store_city, res.data.store_district]
          res.data.main_img_taxpayer = res.data.main_img_taxpayer.map(url => { return { url } })
          res.data.store_img = res.data.store_img.map(url => { return { url } })
          store.signForm = res.data
        })
      }
      if (to.name === 'Login') {
        if (store.totalStep === 1) {
          router.replace('/sign/index')
        } else if (store.totalStep === 2) {
          router.replace('/index')
        } 
      } else {
        if (store.totalStep === 1) {
          router.replace('/sign/index')
        } else if (store.totalStep === 2) {
          next({ ...to, replace: true })
        }
      } 
    })
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      next({ path: '/login' })
    }
    store.step = 1
  }
}
// 路由权限筛选
function authFilter() {
  const store = mainStore()
  const routes = []
  asyncRoutes.map(item => {
    if (store.auth.includes(item.auth) || item.auth === 'constant') {
      if (!item.children) {
        routes.push(item)
      } else {
        const child = []
        item.children.map((v, k) => {
          if (store.auth.includes(v.auth)) {
            child.push(v)
          }
        })
        item.children = child
        if(item.redirect) { // 如果路由设置了重定向，则默认重定向到第一个子菜单
          item.redirect = child[0]
        }
        routes.push(item)
      }
    }
  })
  routes.map(item => {
    router.addRoute(item)
  })
}
router.afterEach(to => {
  NProgress.done()
  document.title = to.meta.title
})
