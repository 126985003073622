<template>
  <div class="sign_topbar">
    <div class="sign_topbar_main">
      <img src="/images/logo.png">
      <span>商家中心</span>
    </div>
  </div>
  <router-view />
</template>
<script setup>

</script>
<style lang="less" scoped>
.sign_topbar {
  width: 100%;
  height: 80px;
  background-color: @color_main;
  color: #fff;
  display: flex;
  align-items: center;
}
.sign_topbar .sign_topbar_main span {
  font-weight: 700;
  font-size: 24px;
  margin-left: 30px;
}
.sign_topbar .sign_topbar_main {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
</style>