import { defineStore } from 'pinia'
import { getStoreCity } from '@/api/home'

export const mainStore = defineStore('main', {
  state: () => {
    return {
      isBell: false,
      totalStep: 0, // 总进度 0 未获取到 1 入驻中  2 已入驻
      step: 0, // 入驻表单进度
      collapsed: false, // 侧边栏是否收起
      auth: [],
      storeInfo: {
        audit_status: 1
      },
      userInfo: {},
      signForm: {
        store_type: 1,
        main_img_taxpayer: [],
        store_img: [],
        main_type: 2
      },
      cityData: [],
      districtData: [],
      menus: [],
      pageHeight: 800
    }
  },
  getters: {

  },
  actions: {
    setPageHeight() {
      this.pageHeight = window.innerHeight - 120
    },
    getCityData() {
      getStoreCity({ level:2 }).then(res => {
        this.cityData = res.data
      })
      getStoreCity({ level: 3 }).then(res => {
        this.districtData = res.data
      })
    }
  }
})