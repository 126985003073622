<template>
  <div class="layouts_header">
    <router-link to="/" class="flex align-center pointer">
      <img class="layouts_logo" src="/images/logo.png">
      <div class="layouts_line" />
      <span>商家中心</span>
    </router-link>
    <div class="layouts_right">
      <ArrowLeftOutlined style="margin-right:15px;" @click="back" />
      <RedoOutlined style="margin-right:15px;" @click="refresh" />
      <a-badge :dot="store.isBell">
        <BellOutlined @click="toBell" />
      </a-badge>
      <div class="user" style="margin-left: 15px;">
        <img style="margin-right: 10px;width: 24px;" src="/images/u2640.svg">
        <span>{{ phoneNumberHide(store.userInfo.phone) }}</span>
        <span class="line">|</span>
        <a-dropdown>
          <div class="pointer">
            <span>{{ store.userInfo.type_name }}</span>
            <img src="/images/u2641.png" style="width: 24px;">
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0" @click="showChangePass=true">
                <span class="flex align-center"><img class="margin-right-xs" src="/images/u539.svg"> 修改密码</span>
              </a-menu-item>
              <a-menu-item key="1" @click="showChangeMobile=true">
                <span class="flex align-center"><img class="margin-right-xs" src="/images/u538.svg"> 换绑手机号</span>
              </a-menu-item>
              <a-menu-item key="2" @click="quit">
                <span class="flex align-center"><img class="margin-right-xs" src="/images/u536.svg"> 退出登录</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
  <div class="layouts">
    <div class="left" :style="`width:${store.collapsed?50:210}px`">
      <div class="lay_sidebar" style="padding-bottom:40px">
        <sidebar />
      </div>
      <div class="sidebar_btn" :style="`width:${store.collapsed?50:210}px`">
        <img src="/images/MenuFold.png" @click="collapsedChange">
      </div>
    </div>
    <div class="right">
      <navbar />
      <div class="content_box">
        <div class="contents">
          <router-view :key="route.fullPath" />
        </div>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="showChangeMobile" title="换绑手机号" @ok="handleChangeMobile">
    <div>
      <div class="vform_item align-center">
        <div class="vform_item_title">当前手机号码</div>
        <div>
          <a-input v-model:value="store.userInfo.phone" disabled style="color:#333;" />
        </div>
      </div>
      <div class="vform_item align-center">
        <div class="vform_item_title"><span class="text-red">*</span>旧手机验证码</div>
        <div>
          <a-input-search v-model:value="mobileForm.oldcode" placeholder="请输入" @search="getCodefun">
            <template #enterButton>
              <a-button v-if="isTo">获取</a-button>
              <a-button v-else>{{ isToTime }} 秒</a-button>
            </template>
          </a-input-search>
        </div>
      </div>
      <div class="vform_item align-center">
        <div class="vform_item_title"><span class="text-red">*</span>新手机号码</div>
        <div>
          <a-input v-model:value="mobileForm.phone" :maxlength="11" placeholder="请输入" />
        </div>
      </div>
      <div class="vform_item align-center">
        <div class="vform_item_title"><span class="text-red">*</span>新手机验证码</div>
        <div>
          <a-input-search v-model:value="mobileForm.code" placeholder="请输入" @search="getCodefun('new')">
            <template #enterButton>
              <a-button v-if="isToN">获取</a-button>
              <a-button v-else>{{ isToTimeN }} 秒</a-button>
            </template>
          </a-input-search>
        </div>
      </div>
    </div>
  </a-modal>
  <a-modal v-model:visible="showChangePass" title="修改密码" @ok="handleChangePass">
    <div>
      <div class="vform_item align-center">
        <div class="vform_item_title"><span class="text-red">*</span>旧密码</div>
        <div>
          <a-input-password
            v-model:value="passForm.oldpwd"
            allow-clear
            :maxlength="16"
            placeholder="请输入"
          />
        </div>
      </div>
      <div class="vform_item align-center">
        <div class="vform_item_title"><span class="text-red">*</span>新密码</div>
        <div>
          <a-input-password
            v-model:value="passForm.pwd"
            allow-clear
            :maxlength="16"
            placeholder="8~16位，包含字母和数字"
          />
        </div>
      </div>
      <div class="vform_item align-center">
        <div class="vform_item_title"><span class="text-red">*</span>确认新密码</div>
        <div>
          <a-input-password
            v-model:value="passForm.cpwd"
            allow-clear
            :maxlength="16"
            placeholder="请输入"
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { phoneNumberHide } from '@/utils'
import sidebar from './sidebar.vue'
import navbar from './navbar.vue'
import { useRoute, useRouter } from 'vue-router'
import { mainStore } from '@/store/index'
import { Modal } from 'ant-design-vue'
import { removeToken } from '@/utils/auth'
import { storeUserChangePass, storeLogout, storeUserChangePhoneSms, storeUserChangePhone } from '@/api/home'
import { ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { ArrowLeftOutlined, RedoOutlined, BellOutlined } from '@ant-design/icons-vue'
import { storeNoticeMark } from '@/api/home'

const store = mainStore()
const route = useRoute()
const router = useRouter()

function collapsedChange() {
  store.collapsed = !store.collapsed
}
function toBell() {
  router.push('/message')
}
function refresh() {
  router.go(0)
}
function back() {
  router.go(-1)
}
function quit() {
  Modal.confirm({
    content: '是否确认退出登录?',
    onOk() {
      storeLogout().then(() => {
        removeToken()
        router.go(0)
      })
    }
  })
}

const mobileForm = ref({})

const isToTime = ref(60)
const isToTimeN = ref(60)
const isTo = ref(true)
const isToN = ref(true)
const timer = ref(null)
const timerN = ref(null)
function getCodefun(type) {
  if (type === 'new') {
    if (!mobileForm.value.phone) {
      message.error('请输入新手机号码')
      return
    }
    storeUserChangePhoneSms({ is_new: 1, phone: mobileForm.value.phone }).then(res => {
      message.success('短信发送成功')
      timerN.value = setInterval(() => {
        if (isToTimeN.value > 0 && isToTimeN.value <= 60) {
          isToTimeN.value--
        } else {
          isToN.value = true
          clearInterval(timerN.value)
          timerN.value = null
          isToTimeN.value = 60
        }
      }, 1000)
      isToN.value = false
    })
    return
  }
  storeUserChangePhoneSms({ phone: store.userInfo.phone }).then(res => {
    message.success('短信发送成功')
    timer.value = setInterval(() => {
      if (isToTime.value > 0 && isToTime.value <= 60) {
        isToTime.value--
      } else {
        isTo.value = true
        clearInterval(timer.value)
        timer.value = null
        isToTime.value = 60
      }
    }, 1000)
    isTo.value = false
  })
}

const showChangeMobile = ref(false)
function handleChangeMobile() {
  const form = Object.assign({}, mobileForm.value)
  storeUserChangePhone(form).then(res => {
    message.success('操作成功')
    store.userInfo.phone = form.phone
    mobileForm.value = {}
    showChangeMobile.value = false
  })
}

const showChangePass = ref(false)
const passForm = ref({})
function handleChangePass() {
  const form = Object.assign({}, passForm.value)
  if (!form.oldpwd) {
    message.error('请输入旧密码')
    return
  }
  if (!form.pwd) {
    message.error('请输入新密码')
    return
  }
  if (!form.cpwd) {
    message.error('请输入确认新密码')
    return
  }
  if (form.pwd !== form.cpwd) {
    message.error('两次密码输入不一致')
    return
  }
  storeUserChangePass(form).then(res => {
    removeToken()
    Modal.success({
      title: '修改成功',
      content: '密码已修改，需要重新登录',
      onOk() {
        router.go(0)
      }
    })
    passForm.value = {}
    showChangePass.value = false
  })
}
const isBell = ref(false)
onMounted(() => {
  storeNoticeMark().then(res => {
    res.data.unread ? store.isBell = true : store.isBell = false
  })
})
</script>
<style lang="less" scoped>
.content_box {
  max-height: calc(100% - 55px);
  overflow: auto;
}
.layouts_header {
  width: 100%;
  min-width: 1340px;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #13346A;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .layouts_logo {
    margin-left: 25px;
    height: 35px;
  }
  .layouts_line {
    width: 2px;
    height: 20px;
    background-color: #fff;
    margin: 0 10px;
  }
  span {
    font-size: 18px;
    color: #fff;
  }
  .layouts_right {
    display: flex;
    align-items: center;

    .user {
      display: flex;
      align-items: center;
      margin-right: 10px;
      span {
        font-size: 14px;
      }
      .line {
        margin: 0 6px;
      }
    }
  }
}
.layouts {
  display: flex;
  height: 100%;
  padding-top: 45px;
  position: relative;
  .left {
    height: 100%;
    background-color: #fff;
    overflow: auto;
    position: relative;
    .sidebar_btn {
      height: 40px;
      position: fixed;
      bottom: 0;
      box-shadow: inset 0px 1px 0px 0px #F0F0F0;
      display: flex;
      align-items: center;
      padding-left: 15px;
      background-color: #fff;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
  .left::-webkit-scrollbar {
    width: 6px;
    // background-color: #fff;
  }
  .left::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 8px;
  }

  // .left:hover::-webkit-scrollbar-thumb {
  //   width: 6px;
  //   background: rgba(144, 147, 153, 0.3);
  //   border-radius: 8px;
  // }
  .left:hover::-webkit-scrollbar{
    width: 6px;
  }
  .right {
    flex: 1;
    width: 0;
    .contents {
      margin: 20px 24px;
    }
  }
}
</style>