<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { mainStore } from '@/store/index'
// import { watch } from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
const store = mainStore()
dayjs.locale('zh-cn')
store.getCityData()
store.setPageHeight()
if (localStorage.getItem('pageSize') === null) {
  localStorage.setItem('pageSize', 20)
}
</script>
<style>

</style>
