import { createRouter, createWebHashHistory } from 'vue-router'
import SignLayout from '@/layouts/sign'
import Layouts from '@/layouts/index'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layouts,
    redirect: '/index',
    meta: { title: '工作台' },
    children: [
      {
        path: 'index',
        name: 'Index',
        meta: { title: '工作台' },
        component: () => import('@/views/index/index')
      },
      {
        path: 'message',
        name: 'Message',
        meta: { title: '通知消息', activeMenu: '/index' },
        component: () => import('@/views/index/message')
      },
      {
        path: '404',
        name: '404',
        component: () => import('@/views/error/404'),
        meta: { title: '404' }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/sign/login'),
    meta: { title: '登录' }
  },
  {
    path: '/sign',
    name: 'Sign',
    component: SignLayout,
    meta: { title: '商家中心' },
    redirect: '/sign/index',
    children: [
      {
        path: 'index',
        name: 'SignIndex',
        component: () => import('@/views/sign/index'),
        meta: { title: '商家入驻' }
      }
    ]
  }
]
export const asyncRoutes = [
  {
    path: '/setting',
    name: 'Setting',
    component: Layouts,
    meta: { title: '系统设置' },
    redirect: '/setting/store',
    auth: 'setting',
    children: [
      {
        path: 'store',
        name: 'Store',
        component: () => import('@/views/setting/store'),
        meta: { title: '店铺管理' },
        auth: 'setting_store_list'
      },
      {
        path: 'info',
        name: 'StoreInfo',
        component: () => import('@/views/setting/info'),
        meta: { title: '商家主体信息' },
        auth: 'setting_main'
      },
      {
        path: 'logoff',
        name: 'StoreLogoff',
        component: () => import('@/views/setting/info/logoff'),
        meta: { title: '商家注销', activeMenu: '/setting/info' },
        auth: 'setting_main_clear'
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/views/setting/role'),
        meta: { title: '角色权限' },
        auth: 'setting_role_list'
      },
      {
        path: 'staff',
        name: 'Staff',
        component: () => import('@/views/setting/staff'),
        meta: { title: '员工管理' },
        auth: 'setting_user_list'
      },
      {
        path: 'store-apply/:type',
        name: 'StoreApply',
        component: () => import('@/views/setting/store/apply'),
        meta: { title: '店铺申请' },
        auth: 'setting_store_save'
      },
      {
        path: 'store-update/:id',
        name: 'StoreUpdate',
        component: () => import('@/views/setting/store/update'),
        meta: { title: '变更店铺信息', activeMenu: '/setting/store' },
        auth: 'setting_store_update'
      },
      {
        path: 'store-review/:type/:id',
        name: 'StoreReview',
        component: () => import('@/views/setting/store/apply'),
        meta: { title: '店铺申请' },
        auth: 'setting_store_edit'
      },
      {
        path: 'store-info/:id',
        name: 'StoreTypeInfo',
        component: () => import('@/views/setting/store/info'),
        meta: { title: '店铺详情', activeMenu: '/setting/store' },
        auth: 'setting_store_detail'
      },
      {
        path: 'store-change-info/:cid',
        name: 'StoreChangeInfo',
        component: () => import('@/views/setting/store/info'),
        meta: { title: '店铺变更详情', activeMenu: '/setting/store' },
        auth: 'setting_store_change'
      },
      {
        path: 'editinfo',
        name: 'EditStoreInfo',
        component: () => import('@/views/setting/info/edit'),
        meta: { title: '变更主体信息', activeMenu: '/setting/info' },
        auth: 'setting_main_update'
      },
      {
        path: 'editinfo/:id',
        name: 'EditStoreDetail',
        component: () => import('@/views/setting/info/detail'),
        meta: { title: '主体变更信息', activeMenu: '/setting/info' },
        auth: 'setting_main_change'
      }
    ]
  },
  {
    path: '/used',
    name: 'Used',
    component: Layouts,
    redirect: '/used/list',
    meta: { title: '二手机店铺' },
    auth: 'used',
    children: [
      {
        path: 'list',
        name: 'UsedList',
        component: () => import('@/views/rent/list'),
        meta: { title: '二手机管理', type: 'used' },
        auth: 'used_equip_list'
      },
      {
        path: 'post',
        name: 'UsedPost',
        component: () => import('@/views/rent/post'),
        meta: { title: '新增商品', type: 'used', activeMenu: '/used/list' },
        auth: 'used_equip_save'
      },
      {
        path: 'edit/:id',
        name: 'UsedEdit',
        component: () => import('@/views/rent/post'),
        meta: { title: '编辑商品', type: 'used', activeMenu: '/used/list' },
        auth: 'used_equip_update'
      },
      {
        path: 'info/:id',
        name: 'UsedInfo',
        component: () => import('@/views/rent/info'),
        meta: { title: '商品详情', type: 'used', activeMenu: '/used/list' },
        auth: 'used_equip_detail'
      },
      {
        path: 'message',
        name: 'UsedMessage',
        component: () => import('@/views/rent/message'),
        meta: { title: '留言管理', type: 'used',feedback: 'used_feedback_save',del:'used_feedback_delete' },
        auth: 'used_feedback_list'
      },
      {
        path: 'enquiry',
        name: 'UsedEnquiry',
        component: () => import('@/views/rent/enquiry/index'),
        meta: { title: '询价管理', type: 'used',detail: 'used_enquiry_detail', update: 'used_enquiry_update' },
        auth: 'used_enquiry_list'
      },
      {
        path: 'quoted/:id',
        name: 'UsedQuoted',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '询价详情', type: 'used', activeMenu: '/used/enquiry' },
        auth: 'used_enquiry_detail'
      },
      {
        path: 'shoptalk/:id/:shop',
        name: 'UsedShoptalk',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '商家报价', type: 'used', activeMenu: '/used/enquiry' },
        auth: 'used_enquiry_update'
      }
    ]
  },
  {
    path: '/new',
    name: 'New',
    component: Layouts,
    redirect: '/new/list',
    meta: { title: '新机店铺' },
    auth: 'new',
    children: [
      {
        path: 'list',
        name: 'NewList',
        component: () => import('@/views/rent/list'),
        meta: { title: '新机管理', type: 'new' },
        auth: 'new_equip_list'
      },
      {
        path: 'post',
        name: 'NewPost',
        component: () => import('@/views/rent/post'),
        meta: { title: '新增商品', type: 'new', activeMenu: '/new/list' },
        auth: 'new_equip_save'
      },
      {
        path: 'edit/:id',
        name: 'NewEdit',
        component: () => import('@/views/rent/post'),
        meta: { title: '编辑商品', type: 'new', activeMenu: '/new/list' },
        auth: 'new_equip_update'
      },
      {
        path: 'info/:id',
        name: 'NewInfo',
        component: () => import('@/views/rent/info'),
        meta: { title: '商品详情', type: 'new', activeMenu: '/new/list' },
        auth: 'new_equip_detail'
      },
      {
        path: 'message',
        name: 'NewMessage',
        component: () => import('@/views/rent/message'),
        meta: { title: '留言管理', type: 'new',feedback: 'new_feedback_save',del:'new_feedback_delete' },
        auth: 'new_feedback_list'
      },
      {
        path: 'enquiry',
        name: 'NewEnquiry',
        component: () => import('@/views/rent/enquiry/index'),
        meta: { title: '询价管理', type: 'new',detail: 'new_enquiry_detail', update: 'new_enquiry_update' },
        auth: 'new_enquiry_list'
      },
      {
        path: 'quoted/:id',
        name: 'NewQuoted',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '询价详情', type: 'new', activeMenu: '/new/enquiry' },
        auth: 'new_enquiry_detail'
      },
      {
        path: 'shoptalk/:id/:shop',
        name: 'NewShoptalk',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '商家报价', type: 'new', activeMenu: '/new/enquiry' },
        auth: 'new_enquiry_update'
      }
    ]
  },
  {
    path: '/rent',
    name: 'Rent',
    component: Layouts,
    redirect: '/rent/list',
    meta: { title: '租赁店铺' },
    auth: 'rent',
    children: [
      {
        path: 'list',
        name: 'RentList',
        component: () => import('@/views/rent/list'),
        meta: { title: '租赁设备', type: 'rent' },
        auth: 'rent_equip_list'
      },
      {
        path: 'post',
        name: 'RentPost',
        component: () => import('@/views/rent/post'),
        meta: { title: '新增商品', type: 'rent', activeMenu: '/rent/list' },
        auth: 'rent_equip_save'
      },
      {
        path: 'edit/:id',
        name: 'RentEdit',
        component: () => import('@/views/rent/post'),
        meta: { title: '编辑商品', type: 'rent', activeMenu: '/rent/list' },
        auth: 'rent_equip_update'
      },
      {
        path: 'info/:id',
        name: 'RentInfo',
        component: () => import('@/views/rent/info'),
        meta: { title: '商品详情', type: 'rent', activeMenu: '/rent/list' },
        auth: 'rent_equip_detail'
      },
      {
        path: 'compete',
        name: 'RentCompete',
        component: () => import('@/views/rent/compete/compete'),
        meta: { title: '竞价求租' },
        auth: 'rent_bidding_list'
      },
      {
        path: 'competeinfo/:id',
        name: 'RentCompeteInfo',
        component: () => import('@/views/rent/compete/info'),
        meta: { title: '竞价求租详情', activeMenu: '/rent/compete' },
        auth: 'rent_bidding_detail'
      },
      {
        path: 'answer/:id',
        name: 'RentAnswer',
        component: () => import('@/views/rent/compete/info'),
        meta: { title: '响应需求', activeMenu: '/rent/compete' },
        auth: 'rent_bidding_detail'
      },
      {
        path: 'offer/:id',
        name: 'RentOffer',
        component: () => import('@/views/rent/compete/offer'),
        meta: { title: '立即报价', activeMenu: '/rent/compete' },
        auth: 'rent_bidding_offer'
      },
      {
        path: 'bidding',
        name: 'RentBidding',
        component: () => import('@/views/rent/bidding/list'),
        meta: { title: '响应管理' },
        auth: 'rent_bidding_offer_list'
      },
      {
        path: 'biddinginfo/:id',
        name: 'RentBiddingInfo',
        component: () => import('@/views/rent/bidding/info'),
        meta: { title: '竞价报价详情', activeMenu: '/rent/bidding' },
        auth: 'rent_bidding_offer_detail'
      },
      {
        path: 'biddingedit/:id',
        name: 'RentBiddingEdit',
        component: () => import('@/views/rent/compete/offer'),
        meta: { title: '竞价报价编辑', activeMenu: '/rent/bidding' },
        auth: 'rent_bidding_offer_update'
      },
      {
        path: 'message',
        name: 'RentMessage',
        component: () => import('@/views/rent/message'),
        meta: { title: '留言管理', type: 'rent',feedback:'rent_feedback_save',del:'rent_feedback_delete' },
        auth: 'rent_feedback_list'
      },
      {
        path: 'enquiry',
        name: 'RentEnquiry',
        component: () => import('@/views/rent/enquiry/index'),
        meta: { title: '询价管理', type: 'rent',detail:'rent_enquiry_detail', update: 'rent_enquiry_update' },
        auth: 'rent_enquiry_list'
      },
      {
        path: 'quoted/:id',
        name: 'RentQuoted',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '询价详情', type: 'rent', activeMenu: '/rent/enquiry' },
        auth: 'rent_enquiry_detail'
      },
      {
        path: 'shoptalk/:id/:shop',
        name: 'RentShoptalk',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '商家报价', type: 'rent', activeMenu: '/rent/enquiry'  },
        auth: 'rent_enquiry_update'
      }
    ]
  },
  {
    path: '/parts',
    name: 'Parts',
    component: Layouts,
    redirect: '/parts/list',
    meta: { title: '配件店铺' },
    auth: 'parts',
    children: [
      {
        path: 'list',
        name: 'PartsList',
        component: () => import('@/views/rent/list'),
        meta: { title: '配件管理', type: 'parts' },
        auth: 'parts_goods_list'
      },
      {
        path: 'post',
        name: 'PartsPost',
        component: () => import('@/views/rent/post'),
        meta: { title: '新增商品', type: 'parts', activeMenu: '/parts/list' },
        auth: 'parts_goods_save'
      },
      {
        path: 'edit/:id',
        name: 'PartsEdit',
        component: () => import('@/views/rent/post'),
        meta: { title: '编辑商品', type: 'parts', activeMenu: '/parts/list' },
        auth: 'parts_goods_update'
      },
      {
        path: 'info/:id',
        name: 'PartsInfo',
        component: () => import('@/views/rent/info'),
        meta: { title: '商品详情', type: 'parts', activeMenu: '/parts/list' },
        auth: 'parts_goods_detail'
      },
      {
        path: 'message',
        name: 'PartsMessage',
        component: () => import('@/views/rent/message'),
        meta: { title: '留言管理', type: 'parts',feedback: 'parts_feedback_save',del:'parts_feedback_delete' },
        auth: 'parts_feedback_list'
      },
      {
        path: 'enquiry',
        name: 'PartsEnquiry',
        component: () => import('@/views/rent/enquiry/index'),
        meta: { title: '询价管理', type: 'parts',detail: 'parts_enquiry_detail', update: 'parts_enquiry_update' },
        auth: 'parts_enquiry_list'
      },
      {
        path: 'quoted/:id',
        name: 'PartsQuoted',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '询价详情', type: 'parts', activeMenu: '/parts/enquiry'},
        auth: 'parts_enquiry_detail'
      },
      {
        path: 'shoptalk/:id/:shop',
        name: 'PartsShoptalk',
        component: () => import('@/views/rent/enquiry/info'),
        meta: { title: '商家报价', type: 'parts', activeMenu: '/parts/enquiry'},
        auth: 'parts_enquiry_update'
      }
    ]
  },
  { path: '/:pathMatch(.*)', meta: { title: '404' }, redirect: '/404', auth: 'constant' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
