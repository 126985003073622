import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import 'ant-design-vue/dist/antd.less'
import router from './router'
import './style/index.less'
import './permission'
import v3ImgPreview from 'v3-img-preview'
const app = createApp(App).use(router)

// 引入pinia
import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)
import { mainStore } from './store'
const store = mainStore()

app.use(v3ImgPreview)

app.directive('auth', {
  mounted(el, binding) {
    const { value } = binding
    if (value) {
      const hasPermission = store.auth.includes(value)
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('error!')
    }
  }
})

app.use(Antd).mount('#app')