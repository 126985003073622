
/**
 * 手机号中间四位转星号
 */
export function phoneNumberHide(tel) {
  tel = '' + tel
  return tel.substr(0, 3) + '****' + tel.substr(7)
}
/**
 * 数组按数量分组
 * example: array ['法国', '澳大利亚', '智利', '新西兰', '西班牙', '加拿大'] num 2
 * => [['法国', '澳大利亚'], ['智利', '新西兰'], ['西班牙', '加拿大']]
 */
export function groupArray(array, num) {
  var index = 0
  var newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, index += num))
  }
  return newArray
}
export function toThousands(num) {
  if (!num) {
    return '0.00'
  }
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(num)) {
    return num
  }
  var a = RegExp.$1; var b = RegExp.$2; var c = RegExp.$3
  var re = new RegExp().compile('(\\d)(\\d{3})(,|$)')
  while (re.test(b)) {
    b = b.replace(re, '$1,$2$3')
  }
  return a + '' + b + '' + c
}
export function validIdCard(cardNo) {
  if (!cardNo || cardNo.length !== 18) {
    return false
  }
  // 17位数字本体码权重
  const w = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // mod11，对应校验码字符值
  const v = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
  let sum = 0
  for (let i = 0; i < 17; i++) {
    sum += cardNo.charAt(i) * w[i]
  }
  return cardNo.charAt(17).toUpperCase() === v[sum % 11]
}
