import axios from 'axios'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'
import { message } from 'ant-design-vue'
import CryptoJS from 'crypto-js'

// 密钥
var key = '9p368NONwyJtHesy'
// 密钥偏移量
var iv = '9238567840125512'

function decrypt(ciphertext) {
  var decrypt = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypt.toString(CryptoJS.enc.Utf8)
}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 0 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Authori-zation'] = 'Bearer ' + getToken()
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
let isLoginMessage = true // 修复登录过期提示弹多次问题
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  response => {
    const res = response.data
    const isProd = process.env.NODE_ENV === 'production'
    console.log(process.env.NODE_ENV)
    if (res.data && isProd) {
      res.data = JSON.parse(decrypt(res.data))
    }
    if (res.status === 200) {
      return res
    } else if (res.status === 410000 || res.status === 410002) {
      // 未登录
      removeToken()
      location.reload()
      message.error(res.msg)
      return Promise.reject()
    } else {
      message.error(res.msg)
      return Promise.reject()
    }
  },
  error => {
    const res = error.response.data
    if (res.code === 410000 || res.code === 410002) {
      removeToken()
      if (isLoginMessage) {
        message.error(res.message)
        isLoginMessage = false
      }
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      message.error(res.message)
    }
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
