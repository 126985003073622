<template>
  <div v-if="menus.length" style="width:100%" class="layouts_sidebar">
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      :inline-collapsed="store.collapsed"
    >
      <a-sub-menu v-for="(item) in menus" :key="item.url">
        <template #icon>
          <img class="menu_icon" :src="`/images/${item.icon}.png`">
        </template>
        <template #title>{{ item.name }}</template>
        <a-menu-item v-for="child in item.child" :key="child.url" @click="toLink(child.url)">{{ child.name }}</a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { mainStore } from '@/store'
import { storeToRefs } from 'pinia'

const router = useRouter()
const route = useRoute()
const store = mainStore()

const { menus } = storeToRefs(store)

function toLink(url) {
  router.push(url)
}

const openKeys = ref([])
const selectedKeys = ref([])
watch(() => store.menus.length, () => {
  menuChange()
})
watch(() => route.path, () => {
  menuChange()
})
function menuChange() {
  if (store.menus.length > 0) {
    // if (!store.collapsed) {
    //   openKeys.value = store.menus.map(item => item.url)
    // }
    const selectedKeysPre = []
    store.menus.map(v => {
      v.child.map(item => {
        if (item.url === route.path || item.url === route.meta.activeMenu) {
          selectedKeysPre.push(item.url)
        }
      })
    })
    selectedKeys.value = selectedKeysPre
  }
}
onMounted(() => {
  if (!store.collapsed) {
    openKeys.value = store.menus.map(item => item.url)
  }
  menuChange()
})

</script>
<style scoped>
.menu_icon {
  width: 20px;
  height: 20px;
}
</style>
<style lang="less">
.layouts_sidebar .ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}
.layouts_sidebar {
  .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: 0px;
    }
}
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0 !important;
  padding-left: 15px !important;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 50px !important;
}
.ant-menu-submenu-title .ant-menu-item-icon {
  max-width: 20px !important;
}
.layouts_sidebar .ant-menu-inline .ant-menu-item::after {
  border-right: 4px solid #13346A;
}
</style>