import request from '@/utils/request'

export function getStoreCity(params) {
  return request({
    url: '/store/city',
    method: 'get',
    params
  })
}
export function getStoreCaptcha(params) {
  return request({
    url: '/store/captcha',
    method: 'get',
    params
  })
}
export function getSmsCode(data) {
  return request({
    url: '/store/sms',
    method: 'post',
    data
  })
}
export function getSmsFindCode(data) {
  return request({
    url: '/store/findpass/sms',
    method: 'post',
    data
  })
}
export function storeUserCheckPhone(params) {
  return request({
    url: '/store/user/check_phone',
    method: 'get',
    params
  })
}
export function storeUserCheckSms(data) {
  return request({
    url: '/store/user/check_sms',
    method: 'post',
    data
  })
}
export function storeApplySave(data) {
  return request({
    url: '/store/apply/save',
    method: 'post',
    data
  })
}
export function storeStep(data) {
  return request({
    url: '/store/step',
    method: 'post',
    data
  })
}
export function storeApplyEdit(params) {
  return request({
    url: '/store/apply/edit',
    method: 'get',
    params
  })
}
export function storeApplyUpdate(data) {
  return request({
    url: '/store/apply/update',
    method: 'post',
    data
  })
}

// home
export function homeLogin(data) {
  return request({
    url: '/store/login',
    method: 'post',
    data
  })
}
export function homeUpload(data) {
  return request({
    url: '/store/pic',
    method: 'post',
    data
  })
}
export function storeFindpass(data) {
  return request({
    url: '/store/findpass',
    method: 'post',
    data
  })
}
export function storeUserInfo(params) {
  return request({
    url: '/store/user/info',
    method: 'get',
    params
  })
}
export function storeUserChangePass(data) {
  return request({
    url: '/store/user/changepass',
    method: 'post',
    data
  })
}
export function storeLogout(data) {
  return request({
    url: '/store/logout',
    method: 'post',
    data
  })
}
export function storeUserChangePhoneSms(data) {
  return request({
    url: '/store/user/changephone_sms',
    method: 'post',
    data
  })
}
export function storeUserChangePhone(data) {
  return request({
    url: '/store/user/changephone',
    method: 'post',
    data
  })
}
export function storeLabel(params) {
  return request({
    url: '/store/label',
    method: 'get',
    params
  })
}
export function storePanel(params) {
  return request({
    url: '/store/panel',
    method: 'get',
    params
  })
}
export function storeNoticeList(params) {
  return request({
    url: '/store/notice/list',
    method: 'get',
    params
  })
}
export function storeNoticeMark(params) {
  return request({
    url: '/store/notice/mark',
    method: 'get',
    params
  })
}
export function storeNoticeMarkPost(data) {
  return request({
    url: '/store/notice/mark',
    method: 'post',
    data
  })
}
export function storeWord(params) {
  return request({
    url: '/store/word',
    method: 'get',
    params
  })
}
// 留言管理列表
export function rentFeedbackList(params) {
  return request({
    url: '/store/rent/feedback/list',
    method: 'get',
    params
  })
}
export function partsFeedbackList(params) {
  return request({
    url: '/store/parts/feedback/list',
    method: 'get',
    params
  })
}
export function usedFeedbackList(params) {
  return request({
    url: '/store/used/feedback/list',
    method: 'get',
    params
  })
}
export function newFeedbackList(params) {
  return request({
    url: '/store/new/feedback/list',
    method: 'get',
    params
  })
}
// 删除
export function rentDelete(data) {
  return request({
    url: '/store/rent/feedback/delete',
    method: 'post',
    data
  })
}
export function partsDelete(data) {
  return request({
    url: '/store/parts/feedback/delete',
    method: 'post',
    data
  })
}
export function usedDelete(data) {
  return request({
    url: '/store/used/feedback/delete',
    method: 'post',
    data
  })
}
export function newDelete(data) {
  return request({
    url: '/store/new/feedback/delete',
    method: 'post',
    data
  })
}
// 发表
export function rentSave(data) {
  return request({
    url: '/store/rent/feedback/save',
    method: 'post',
    data
  })
}
export function partsSave(data) {
  return request({
    url: '/store/parts/feedback/save',
    method: 'post',
    data
  })
}

export function usedSave(data) {
  return request({
    url: '/store/used/feedback/save',
    method: 'post',
    data
  })
}

export function newSave(data) {
  return request({
    url: '/store/new/feedback/save',
    method: 'post',
    data
  })
}
// 询价列表
export function rentEqList(params) {
  return request({
    url: '/store/rent/enquiry/list',
    method: 'get',
    params
  })
}
export function partsEqList(params) {
  return request({
    url: '/store/parts/enquiry/list',
    method: 'get',
    params
  })
}
export function usedEqList(params) {
  return request({
    url: '/store/used/enquiry/list',
    method: 'get',
    params
  })
}
export function newEqList(params) {
  return request({
    url: '/store/new/enquiry/list',
    method: 'get',
    params
  })
}
// 处理询价
export function rentEqUpdate(data) {
  return request({
    url: '/store/rent/enquiry/update',
    method: 'post',
    data
  })
}
export function partsEqUpdate(data) {
  return request({
    url: '/store/parts/enquiry/update',
    method: 'post',
    data
  })
}
export function usedEqUpdate(data) {
  return request({
    url: '/store/used/enquiry/update',
    method: 'post',
    data
  })
}
export function newEqUpdate(data) {
  return request({
    url: '/store/new/enquiry/update',
    method: 'post',
    data
  })
}
// 询价详情
export function rentDetail(params) {
  return request({
    url: '/store/rent/enquiry/detail',
    method: 'get',
    params
  })
}
export function usedDetail(params) {
  return request({
    url: '/store/used/enquiry/detail',
    method: 'get',
    params
  })
}
export function partsDetail(params) {
  return request({
    url: '/store/parts/enquiry/detail',
    method: 'get',
    params
  })
}
export function newDetail(params) {
  return request({
    url: '/store/new/enquiry/detail',
    method: 'get',
    params
  })
}
export function rentDetail1(params) {
  return request({
    url: '/store/rent/enquiry/update',
    method: 'get',
    params
  })
}
export function usedDetail1(params) {
  return request({
    url: '/store/used/enquiry/update',
    method: 'get',
    params
  })
}
export function partsDetail1(params) {
  return request({
    url: '/store/parts/enquiry/update',
    method: 'get',
    params
  })
}
export function newDetail1(params) {
  return request({
    url: '/store/new/enquiry/update',
    method: 'get',
    params
  })
}
export function storeSms(data) {
  return request({
    url: '/store/main/clear_sms',
    method: 'post',
    data
  })
}
export function storeSettingMainClear(data) {
  return request({
    url: '/store/setting/main/clear',
    method: 'post',
    data
  })
}
